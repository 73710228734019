import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage, IonRow
} from '@ionic/react';

import { useTranslation } from "react-i18next";

import './Error.css';


const Error: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonRow>
          <IonCard>
            <img alt="logo" src="assets/images/asksuite_logo.png" />
            <IonCardContent>
              {t('FAILED_TO_LOAD')}
            </IonCardContent>
          </IonCard>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Error;
