import axios from 'axios';
import { environment } from '../../../environments/environment';
import ActivateStartResponseInterface from '../interfaces/activate-start-response.interface';
import ActivateOrganizationsResponseInterface from '../interfaces/activate-organizations-response.interface';
import { ActivateSaveCompanyInterface } from '../interfaces/activate-save-company.interface';

class ActivateService {
  private axios = axios.create({
    baseURL: environment.activate.baseURL,
  })

  async start(organizationId: string, companyId: string): Promise<ActivateStartResponseInterface> {
    return this.axios.get('/start', {
      params: {
        organizationId,
        companyId,
      }
    }).then(res => res.data)
  }

  async getOrganizations(): Promise<ActivateOrganizationsResponseInterface> {
    return this.axios.get('/organizations')
      .then(res => res.data)
  }

  async saveCompany(saveCompanyData: ActivateSaveCompanyInterface): Promise<void> {
    return this.axios.post('/save', saveCompanyData)
      .then(() => {})
  }
}

export default new ActivateService();
