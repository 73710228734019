import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import i18next from 'i18next';
import Error from './pages/error/Error';
import SelectOrganization from './pages/activate/SelectOrganization';
import SelectNumber from './pages/activate/SelectNumber';

setupIonicReact();

const App: React.FC = () => {
  let lang = new URLSearchParams(window.location.search)?.get('lang')?.toString()?.substring(0, 2)
  if (!lang) {
    lang = localStorage.getItem('lang') || undefined;
  } else {
    localStorage.setItem('lang', lang);
  }
  i18next.changeLanguage(lang);
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/">
            <SelectOrganization />
          </Route>
          <Route exact path="/select-number">
            <SelectNumber />
          </Route>
          <Route exact path="/error">
            <Error />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
}

export default App;
