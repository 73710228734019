import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText
} from '@ionic/react';
import { object, string } from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";

import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import ActivateService from './services/activate.service';
import { OrganizationDto } from './interfaces/activate-organizations-response.interface';
import { SearchbarChangeEventDetail } from '@ionic/core';
import { IonSearchbarCustomEvent } from '@ionic/core/dist/types/components';
import './SelectOrganization.css';

const SelectOrganization: React.FC = () => {
  const {t} = useTranslation();
  const validationSchema = object().shape({
    organizationId: string()
      .required(t('REQUIRED_FIELD', {fieldName: 'ORGANIZATION'}))
  });

  const {register, handleSubmit, setValue, formState: {errors}} = useForm({
      resolver: yupResolver(validationSchema),
      reValidateMode: 'onBlur',
    },
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<OrganizationDto[]>([]);
  const [companyId, setCompanyId] = useState<string>();
  const [companyName, setCompanyName] = useState<string | undefined>();
  const [textFilter, setTextFilter] = useState<string | null | undefined>();
  const history = useHistory();

  const handleSubmitOuter = (formState: any) => {
    setLoading(true);
    history.push(`/select-number?organizationId=${formState.organizationId}&companyId=${companyId}&companyName=${companyName || ''}`)
    setLoading(false);
  }

  const loadOrganizations = async () => {
    return ActivateService.getOrganizations()
      .then((res) => {
        setOrganizations(res.organizations);
      })
  }

  const updateFilter = (event: IonSearchbarCustomEvent<SearchbarChangeEventDetail>) => {
    setTextFilter(event.target.value?.toLowerCase());
  }

  const filterOrganizations = (organization: OrganizationDto): boolean => {
    if (!textFilter) {
      return true;
    }
    return organization.name.toLowerCase().includes(textFilter) || organization.id.toLowerCase().includes(textFilter) ||
      organization.timeZone.toLowerCase().includes(textFilter);
  }

  useEffect(() => {
    setLoading(true);
    const urlSearchParams = new URLSearchParams(window.location.search);
    loadOrganizations().finally(() => {
      setLoading(false);
      setLoaded(true);
    });
    setCompanyId(urlSearchParams.get('companyId')!)
    if (urlSearchParams.has('companyName')) {
      setCompanyName(urlSearchParams.get('companyName')!)
    }
  }, [])

  return (
    <IonPage>
      {loaded && (!companyId || !organizations.length) && <Redirect to="error"></Redirect>}
      <IonContent fullscreen>
        <IonRow>
          <IonCard>
            <img alt="logo" src="assets/images/asksuite_logo.png"/>
            <IonCardContent>
              {loading ? <IonSpinner name="crescent"/> : (<form onSubmit={handleSubmit(handleSubmitOuter)}>
                <IonCardTitle>{t('ACTIVATING_COMPANY', {companyId: (companyName || companyId)})}</IonCardTitle>
                <IonItem lines="none">
                  <IonLabel>{t('ORGANIZATION')}</IonLabel>
                  <IonSelect
                    placeholder={t('SELECT_ORGANIZATION_ID')} {...register('organizationId')}
                    onIonChange={(e: any) => setValue('organizationId', e.target.value || '')}>
                    {
                      organizations.filter(filterOrganizations).map(organization =>
                        <IonSelectOption key={organization.id}
                                         value={organization.id}>{organization.name}</IonSelectOption>
                      )
                    }
                  </IonSelect>
                </IonItem>
                <IonText color="danger">{errors.email?.message}</IonText>
                <IonRow>
                  <IonCol size="12" class="ion-text-center">
                    <IonButton disabled={loading} type="submit">{t('CONFIRM')} {loading &&
                        <IonSpinner name="crescent"/>}</IonButton>
                  </IonCol>
                </IonRow>
              </form>)}

            </IonCardContent>
          </IonCard>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default SelectOrganization;
