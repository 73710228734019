import {
  IonBackButton,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent, IonHeader,
  IonInput,
  IonItem,
  IonLabel, IonList, IonModal,
  IonPage,
  IonRow, IonSpinner,
  IonText, IonToast
} from '@ionic/react';
import { object, string } from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";

import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import ActivateService from './services/activate.service';
import { environment } from '../../environments/environment';


interface State {
  companyId: string;
  organizationId: string;
  companyName?: string;
}

const SelectNumber: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>()
  const [state, setState] = useState<State>()
  const [phoneNumbers, setPhoneNumbers] = useState<any[]>([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>();
  const history = useHistory();


  const checkState = () => {
    if (!state?.organizationId || !state?.companyId ) {
      setError(true);
    }
  }

  const saveCompany = async () => {
    return ActivateService.saveCompany({
      companyId: state!.companyId,
      organizationId: state!.organizationId,
      phoneNumber: selectedPhoneNumber!,
      companyName: state!.companyName,
    })
      .then(() => {
        setSelectedPhoneNumber(undefined);
        // toaster success
        setToastMessage(t('SUCCESS_TO_SAVE_COMPANY'))
        setShowToast(true);

        // go back to home
        window.location.href = `${environment.activate.redirectUrl}?companyId=${state?.companyId}&companyName=${state?.companyName}`;
      })
      .catch(() => {
        setToastMessage(t('FAILED_TO_SAVE_COMPANY'))
        setSelectedPhoneNumber(undefined);
        setShowToast(true);
        console.log('error')
      })
  }

  const confirmSelection = (phoneNumber: any) => {
    setSelectedPhoneNumber(phoneNumber);
  }

  useEffect(() => {
    setLoading(true);
    const urlSearchParams = new URLSearchParams(window.location.search);
    setState({
      companyId: urlSearchParams.get('companyId')!,
      organizationId: urlSearchParams.get('organizationId')!,
      companyName: urlSearchParams.get('companyName') || undefined,
    })
  }, [])

  useEffect(() => {
    if (!state) {
      return;
    }
    checkState();
    ActivateService.start(state.organizationId, state.companyId)
      .then((res) => {
        setPhoneNumbers(res.phoneNumbers.map(phoneNumber => {
          const usedPhoneNumber = res.usedPhoneNumbers.find(usedPhoneNumber => phoneNumber === usedPhoneNumber.phoneNumber);
          return {
            phoneNumber,
            ...(usedPhoneNumber || {})
          }
        }))
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
      })
  }, [state])

  return (
    <IonPage>
      {state && error && <Redirect to="error"></Redirect>}
      <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={3000} />
      <IonContent fullscreen>
        <IonModal isOpen={!!selectedPhoneNumber}>
          <IonContent className="ion-align-items-center">
            <IonHeader className="ion-padding">
              <IonText><h1>{t('CONFIRM_PHONE_SELECTION')}</h1></IonText>
            </IonHeader>
            <IonItem>
              <IonText>
                {`${t('PHONE_NUMBER')}: ${selectedPhoneNumber}`}
              </IonText>
            </IonItem>
            <IonItem>
              <IonText>
                {`${t('ACTIVATING_COMPANY', {companyId: state?.companyId})}`}
              </IonText>
            </IonItem>
            <IonItem>
              <IonText>
                {`${t('ORGANIZATION_ID')}: ${state?.organizationId}`}
              </IonText>
            </IonItem>
            <IonItem lines="none">
              <IonButton color="light" onClick={() => setSelectedPhoneNumber(undefined)}>{t('CANCEL')}</IonButton>
              <IonButton onClick={() => saveCompany()}>{t('CONFIRM')}</IonButton>
            </IonItem>
          </IonContent>
        </IonModal>
        <IonRow>
          <IonCard>
            <IonBackButton defaultHref={`/?companyId=${state?.companyId}`}/>
            <IonCardContent>
              <IonCardTitle>{t('ACTIVATING_COMPANY', {companyId: state?.companyId})}</IonCardTitle>
              {loading ? <IonSpinner name="crescent" /> : (
                <IonList>
                  {phoneNumbers?.map(phoneNumber => (
                    <IonButton key={`${phoneNumber.phoneNumber}-${phoneNumber.companyId}`} onClick={() => confirmSelection(phoneNumber.phoneNumber)} disabled={phoneNumber.companyId}>
                      {phoneNumber.phoneNumber} - {phoneNumber.companyId || t('AVAILABLE')}
                    </IonButton>)
                  )}
                </IonList>
              )}
            </IonCardContent>
          </IonCard>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default SelectNumber;
